import dayjs from "dayjs";

export function getDateOfWeek(weekString) {
  const year = weekString.slice(0, 4);
  const week = weekString.slice(6);
  var d = (1 + (parseInt(week) - 1) * 7); // 1st of January + 7 days for each week

  return new Date(parseInt(year), 0, d);
}

export function toWeekString(date) {
  const datejs = dayjs(date);
  const monthStart = datejs.date(1);
  
  const firstWeekStart = monthStart.isoWeekday() > 5 
    ? monthStart.add(8 - monthStart.isoWeekday(), 'day') 
    : monthStart;

    if (datejs.add(1, 'month').date(1).isoWeek() === datejs.isoWeek()){
      const year = datejs.add(1, 'month').year() 
      const nextWeek = dayjs().year(year).isoWeek((datejs.isoWeek() + 1)).startOf('isoWeek') // start day of the week next to share week
      const firstDayShareWeek = datejs.add(1, 'month').date(1)

      if (nextWeek.diff(firstDayShareWeek, 'd') > 4 ) 
        return datejs.add(1, 'month').format("MMM") + "-S1";
  }
  
  return datejs.format("MMM")+"-S" + (datejs.isBefore(firstWeekStart) ? 1 
  : Math.ceil((datejs.diff(firstWeekStart, 'd') + 1) / 7));
}

export function toIsoWeekString(date) {
  return date.year().toString() + "-W" + date.isoWeek().toString().padStart(2, "0");
}

export function getLast7Weeks() {
  const dates = [];
  let currentDate = dayjs();

  for (let i = 0; i < 6; i++) {
    dates.push(toIsoWeekString(currentDate));
    currentDate = currentDate.subtract(1, 'week');
  }
  return dates.reverse();
}