import { useSession } from 'next-auth/react';
import { useGetArrayRequest, useGetRequest, usePostRequest } from '../base_hooks';


export function useCompanyInstance(company_id) {
  return useGetRequest(['company', company_id]);
}

export function useCurrentCompany() {
  const session = useSession();
  return useGetRequest(['company', session.data.company]);
}

export function useGetCompany() {
  return useGetArrayRequest(['company']);
}

export function useCreateCompany(successCallback, errorCallback) {
  return usePostRequest(
    ['company'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['company']});
    },
    errorCallback
  );
}