
import { MoreHoriz } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Lock from '@mui/icons-material/Lock';
import { Box, Button, Divider, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import CircularProgressPersonalized from '../CircularProgress/CircularProgress';
import { KeyResultListItem } from '../KeyResultsListItem';
import { ObjectiveForm } from '../ObjectiveForm';

function OkrCard(props) {
  const {objective, isNewObjective} = props;
  const {name, progress, visibility_status} = objective;
  const [expanded, setExpanded] = useState(isNewObjective);

  const objectiveAnimation = {
    animation: "newObjective ease-in 2.5s",
    "@keyframes newObjective": {
      "0%": {
        boxShadow: "0 0 0px transparent",
      },
      "50%": {
        boxShadow: "0 0 12px #0f785f", 
      },
      "100%": {
        boxShadow: "0 0 0px transparent",
      },
    },
  };
  
  useEffect(() => {
    if (isNewObjective) {
      setExpanded(true);
    }
  }, [isNewObjective]); 
  const [objectiveOpen, setObjectiveOpen] = useState(false)

  const handleObjectiveOpen = () => {
    setObjectiveOpen(true);
  };
  const handleObjectiveClose = () => {
    setObjectiveOpen(false);
  };
  const router = useRouter();

  return (
    <>
      <Accordion 
      expanded={expanded} 
      onChange={() => setExpanded((prev) => !prev)}
      disableGutters 
      TransitionProps={{ unmountOnExit: true }}  
      sx={isNewObjective? objectiveAnimation : null}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" gap={2} alignItems="center">
              <CircularProgressPersonalized progress={progress} />
              <Typography variant="body1" align="left" fontWeight={600}>
                {name}
              </Typography>
              {visibility_status == 'PV' && (
                <Tooltip
                  title={
                    <>
                      <Typography color="inherit">
                        Usuários com acesso:
                      </Typography>
                      {objective.users_visibility.map((user) => (
                        <Typography key={user.id} color="inherit">
                          {' '}
                          - {user.name}
                        </Typography>
                      ))}
                    </>
                  }
                  arrow
                  placement="right"
                >
                  <Lock />
                </Tooltip>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" marginRight={2}>
            <IconButton
            variant="outlined"
            sx={{ p: 0 }}
            onClick={(event) => {
              handleObjectiveOpen()
              event.stopPropagation();
            }}        >
            <MoreHoriz />
          </IconButton>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {objective?.key_results?.map((kr, index) => (
            <KeyResultListItem kr={kr} index={index} key={index} />
          ))}
          <Divider sx={{marginTop: '16px'}} />
          <Button
            variant="text"
            onClick={() => router.push(`/objetivo/${objective.id}/criar-kr`)}
            startIcon={<AddIcon />}
            sx={{marginTop: 1}}
            size="small"
          >
            novo key result
          </Button>
        </AccordionDetails>
      </Accordion>
      <Modal open={objectiveOpen} onClose={handleObjectiveClose}>
            <Box
              sx={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                width: '80%',
                maxWidth: '600px',
              }}
            >
              <ObjectiveForm
                    objective={objective}
                    onClose={handleObjectiveClose}
                  />
            </Box>
          </Modal>
    </>
  );
}

export default memo(OkrCard);
